import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "antd/dist/antd.dark.css";
import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";

import NavItems from "./components/NavItems";
import TheHeader from "./components/TheHeader";
import Buy from "./components/Buy";
import Dashboard from "./components/Dashboard";
import Learning from "./components/learning";
import Course1 from "./components/Course1";
import Course2 from "./components/Course2";
import Course3 from "./components/Course3";
import Trading1 from "./components/trading1";
import Trading2 from "./components/Trading2";
import Programming1 from "./components/Programming1";
import Programming2 from "./components/Programming2";
import Programming3 from "./components/Programming3";
import Idiomas1 from "./components/Idiomas1";
import Team from "./components/Team";
import Register from "./components/Register";
import Error404 from "./components/Error404";
import TheFooter from "./components/TheFooter";
import Login from "./components/Login";


export default class App extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    
    return (
      <Router>
        <Layout style={{ minHeight: "100vh" }}>
          <NavItems />
          <Layout className="site-layout">
            <TheHeader />
            <Content>
              <Switch>
              <Route exact path="/" component={Buy} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/buy" component={Buy} />
                <Route exact path="/dashboard" component={Dashboard} />
                <Route exact path="/team" component={Team} />
                <Route exact path="/register" component={Register} />    
                <Route exact path="/learning" component={Learning} />  
                <Route path="/course1/:id" component={Course1} />
                <Route path="/course2/:id" component={Course2} />   
                <Route path="/course3/:id" component={Course3} />     
                <Route path="/Trading1/:id" component={Trading1} />
                <Route path="/Trading2/:id" component={Trading2} /> 
                <Route path="/Programming1/:id" component={Programming1} />
                <Route path="/Programming2/:id" component={Programming2} /> 
                <Route path="/Programming3/:id" component={Programming3} /> 
                <Route path="/Idiomas1/:id" component={Idiomas1} />   
                <Route component={Error404} />
              </Switch>
            </Content>
            <TheFooter />
          </Layout>
        </Layout>
      </Router>
    )
  }
}
