import Web3Modal from "web3modal"
import Web3 from "web3"
import WalletConnectProvider from "@walletconnect/web3-provider"
import { Networks } from './consts'
import { isConnected } from "./metadapp"
import usdtAbi  from '../contracts/usdtAbi'
import { _bnbToWei, _toBigNumber } from '../util/units'
import { message } from "antd"

export class Web3Instance {
    constructor() {
        this.account = null
        this.provider = null
        this.userId = null
        this._web3 = null
        this.web3Modal = null
    }

    async init() {
      try {
          const providerOptions = {
              metamask: {
                  package: window.ethereum,
                  connector: async () => {
                      const provider = window.ethereum;
                      await provider.enable();
                      return provider;
                  }
              }
          };
  
          this.web3Modal = new Web3Modal({
              cacheProvider: true,
              providerOptions
          });
  
          if (isConnected()) {
              await this.connect();
          } else {
              await this.disconnect();
          }
      } catch (err) {
          console.error(err);
      }
  
      return this;
  }
  

    async _getAccount() {
        const account = (await this._web3.eth.getAccounts())[0]
        return account ? account.toLowerCase() : undefined
    }


    async approveTokens(spender,totalAmount, account) {
        const usdtAddress = '0x55d398326f99059fF775485246999027B3197955';
      
        try {
          const usdtContract = new this._web3.eth.Contract(usdtAbi, usdtAddress);
          const transaction = await usdtContract.methods.approve(spender, totalAmount.toString()).send({ from: account });
          if (transaction.status) {
            return '200';
          } else {
            return '400';
          }
        } catch (error) {
          console.error('Failed to approve tokens:', error);
        }
      }

      async allowance(owner, spender) {
        const usdtAddress = '0x55d398326f99059fF775485246999027B3197955';
      
        try {
          const usdtContract = new this._web3.eth.Contract(usdtAbi, usdtAddress);
          const allowance  = await usdtContract.methods.allowance(owner, spender).call();
            return allowance;
       
        } catch (error) {
          console.error('Failed to approve tokens:', error);
        }
      }

      async connect() {
        try {
            const providerOptions = {};
    
            this.web3Modal = new Web3Modal({
                cacheProvider: true,
                providerOptions
            });
    
            const provider = await this.web3Modal.connect();
    
          
                this.provider = provider;
                this._web3 = new Web3(provider);
                this.account = await this._getAccount();
                   
                if (this.account) {
                    this._setupAccount();
                }
    
                this.provider.on('accountsChanged', (accounts) => {
                    this._fetchAccount(accounts);
                });
    
                this.provider.on('disconnect', (error) => {
                    this._dropState();
                });
           
        } catch (err) {
            console.error(err);
            message.error({ content: 'Please connect using your Wallet', duration: 3 });
            this.disconnect();
            return;
        }
    }

    async disconnect() {
        try {
            this._dropState()
            if (this.provider.close) {
                await this.provider.close()
                this.web3Modal.clearCachedProvider()
                message.info({ content: 'Disconnected successfully', duration: 3 })
            }

            this.provider = null
        } catch (err) { }
    }

    _setupAccount() {
        localStorage.setItem('eth_connected', 'y')
        localStorage.setItem('account', this.account)
    }

    _dropState() {
        localStorage.clear()
        this.account = null
        this.provider = null
        this._web3 = null
        
    }

    async _fetchAccount(accounts) {
        if (accounts.length > 0) {
            this.account = accounts[0].toLowerCase()
            this._setupAccount()
        }
    }

    getAccount() {
        return this.account
    }

    getProvider() {
      return this._web3.currentProvider
    }
}